import { gql, useQuery } from "@apollo/client";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetNextBusinessDay } from "api/useGetNextBusinessDay";
import moment from "moment";

const GET_CONTACTS_QUERY = gql`
  query GetPortfolioAccountBalances($contactId: String, $today: String, $settlementStartDateT1: String,
      $settlementEndDateT1: String, $settlementStartDateT2: String) {
      contacts(contactId: $contactId) {
          name
          portfolios {
              id
              name
              shortName
              currency {
                  securityCode
                  amountDecimalCount
              }
              accounts {
                  id
                  name
                  number2
                  currency {
                      securityCode
                  }
              }
              portfolioReport(
                  reportDate: $today
                  calculateExpectedAmountBasedOpenTradeOrders: true
                  calculateExpectedAmountBasedUnsettledTransactions: true
                  useLatestPositionsAmount: true
                  adjustPositionsBasedOnOpenTradeOrders: false
              ) {
                  accountItems {
                      accountId
                      portfolioId
                      currency {
                          securityCode
                      }
                      accountName
                      amountBeforeUnsettledTransactions
                      expectedAmount
                      amountAfterOpenTradeOrders
                      balanceAccCurr
                      buyOpenTradeOrder: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "4"
                      ) {
                          buyOpenTradeAmount: tradeAmount
                      }
                      buyAcceptedTradeOrder: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "5"
                      ) {
                          buyAcceptedTradeAmount: tradeAmount
                      }
                      buyExecutableTradeOrders: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "1"
                      ) {
                          buyExecutableTradeAmount: tradeAmount
                      }
                      buySentToExecutionTradeOrder: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "10"
                      ) {
                          buySentToExecutionTradeAmount: tradeAmount
                      }
                      buyInExecutionTradeOrders: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "6"
                      ) {
                          buyInExecutionTradeAmount: tradeAmount
                      }
                      buyPartiallyExecutedTradeOrders: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "12"
                      ) {
                          buyPartiallyExecutedTradeAmount: tradeAmount
                      }
                      buyExecutedMarketTradeOrders: tradeOrders(
                          typeCodes: ["B", "SUB"]
                          aggregationMode: 2
                          orderStatus: "7"
                      ) {
                          buyExecutedMarketTradeAmount: tradeAmount
                      }
                      sellUnsettledOKTransactionsT1: transactions(
                          aggregationMode: 2
                          settlementStartDate: $settlementStartDateT1
                          settlementEndDate: $settlementEndDateT1
                          typeCodes: ["S", "RED"]
                          status: "OK"
                      ) {
                          sellUnsettledOKTransactionAmountT1: tradeAmount
                      }
                      sellUnsettledOKTransactionsT2: transactions(
                          aggregationMode: 2
                          settlementStartDate: $settlementStartDateT2
                          typeCodes: ["S", "RED"]
                          status: "OK"
                      ) {
                          sellUnsettledOKTransactionAmountT2: tradeAmount
                      }
                      sellUnsettledNFTransactionsT1: transactions(
                          aggregationMode: 2
                          settlementStartDate: $settlementStartDateT1
                          settlementEndDate: $settlementEndDateT1
                          typeCodes: ["S", "RED"]
                          status: "NF"
                      ) {
                          sellUnsettledNFTransactionAmountT1: tradeAmount
                      }
                      sellUnsettledNFTransactionsT2: transactions(
                          aggregationMode: 2
                          settlementStartDate: $settlementStartDateT2
                          typeCodes: ["S", "RED"]
                          status: "NF"
                      ) {
                          sellUnsettledNFTransactionAmountT2: tradeAmount
                      }
                  }
              }
          }
      }
  } 
`;


interface AccountItem {
  accountId: number;
  portfolioId: number;
  currency: {
    securityCode: string;
    amountDecimalCount: number;
  };
  accountName: string;
  amountBeforeUnsettledTransactions: number;
  expectedAmount: number;
  amountAfterOpenTradeOrders: number;
  balanceAccCurr: number;
  buyOpenTradeOrder: { buyOpenTradeAmount: number }[];
  buyAcceptedTradeOrder: { buyAcceptedTradeAmount: number }[];
  buyExecutableTradeOrders: { buyExecutableTradeAmount: number }[];
  buySentToExecutionTradeOrder: { buySentToExecutionTradeAmount: number }[];
  buyInExecutionTradeOrders: { buyInExecutionTradeAmount: number }[];
  buyPartiallyExecutedTradeOrders: { buyPartiallyExecutedTradeAmount: number }[];
  buyExecutedMarketTradeOrders: { buyExecutedMarketTradeAmount: number }[];
  sellUnsettledOKTransactionsT1: { sellUnsettledOKTransactionAmountT1: number }[];
  sellUnsettledOKTransactionsT2: { sellUnsettledOKTransactionAmountT2: number }[];
  sellUnsettledNFTransactionsT1: { sellUnsettledNFTransactionAmountT1: number }[];
  sellUnsettledNFTransactionsT2: { sellUnsettledNFTransactionAmountT2: number }[];
}

interface Contact {
  name: string;
  portfolios: Portfolio[];
}


interface PortfolioAccount {
  id: number;
  name: string;
  number2: string;
  currency: {
    securityCode: string;
  };
}
interface Portfolio {
  id: number;
  name: string;
  shortName: string;
  currency: {
    securityCode: string;
  };
  accounts: PortfolioAccount[];
  portfolioReport: {
    accountItems: AccountItem[];
  };
}

interface ContactsData {
  contacts: Contact[];
}

export interface Balance {
  trading: { currency: string, amount: number }[];
  withdrawal: { currency: string, amount: number }[];
}
interface Balances { 
  [key: string]: Balance
}

export const useGetAvailableBalancesExperimental = () => {
  const { data: contactData, loading:loadingContactInfo } = useGetContactInfo();

  const { data: calendarNextBusinessDay, loading: loadingBd1 } = useGetNextBusinessDay();
  const { data: calendaDATBusinessDay , loading: loadingBd2} = useGetNextBusinessDay(calendarNextBusinessDay); //day after tomorow

  const addBusinessDays = (date: Date, daysToAdd: number) => {
    const WEEKEND = [moment().day("Saturday").weekday(), moment().day("Sunday").weekday()]
    let daysAdded = 0;
    let momentDate = moment(new Date(date));
    while (daysAdded < daysToAdd) {
      momentDate = momentDate.add(1, 'days');
      if (!WEEKEND.includes(momentDate.weekday())) {
        daysAdded++
      }
    }

    return momentDate;
  }

  const currentDate = new Date();
  const nextBusinessDay = addBusinessDays(currentDate, 1);
  const settlementStartDate = addBusinessDays(currentDate, 2);
  
  const variables: { 
    contactId: string | undefined; 
    today: string; 
    settlementStartDateT1: string; 
    settlementEndDateT1: string; 
    settlementStartDateT2: string; 
  } = {
    contactId : contactData?._contactId,
    today: currentDate.toISOString().split('T')[0],
    settlementStartDateT1: calendarNextBusinessDay ?? nextBusinessDay.format('YYYY-MM-DD'),
    settlementEndDateT1: calendarNextBusinessDay ?? nextBusinessDay.format('YYYY-MM-DD'),
    settlementStartDateT2: calendaDATBusinessDay ?? settlementStartDate.format('YYYY-MM-DD'),
  };

  const { loading, error, data, refetch } = useQuery<ContactsData>(GET_CONTACTS_QUERY, {
    variables: variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const balances:Balances = {}

  data?.contacts.forEach((contact: Contact) => {
    contact?.portfolios?.forEach((portfolio: Portfolio) => {
      portfolio?.portfolioReport?.accountItems.forEach((item: AccountItem) => {
        const accountNumber = item.accountName.split('-')[0];
        const currency = item.currency.securityCode;

        const tradingAmount = item.balanceAccCurr - (
          (item.buyOpenTradeOrder[0]?.buyOpenTradeAmount || 0) 
          + (item.buyAcceptedTradeOrder[0]?.buyAcceptedTradeAmount || 0)
          + (item.buyExecutableTradeOrders[0]?.buyExecutableTradeAmount || 0)
          + (item.buySentToExecutionTradeOrder[0]?.buySentToExecutionTradeAmount || 0)
          + (item.buyInExecutionTradeOrders[0]?.buyInExecutionTradeAmount || 0)
          + (item.buyPartiallyExecutedTradeOrders[0]?.buyPartiallyExecutedTradeAmount || 0)
          + (item.buyExecutedMarketTradeOrders[0]?.buyExecutedMarketTradeAmount || 0)
          + (item.sellUnsettledOKTransactionsT2[0]?.sellUnsettledOKTransactionAmountT2 || 0)
        ) + (item.sellUnsettledNFTransactionsT1[0]?.sellUnsettledNFTransactionAmountT1 || 0);

        const withdrawalAmount = tradingAmount - (item.sellUnsettledOKTransactionsT1[0]?.sellUnsettledOKTransactionAmountT1 || 0) - (item.sellUnsettledNFTransactionsT1[0]?.sellUnsettledNFTransactionAmountT1 || 0);

        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }

        balances[accountNumber].trading.push({ currency, amount: tradingAmount });
        balances[accountNumber].withdrawal.push({ currency, amount: withdrawalAmount });
      });
    });
  });

  if (contactData?.accountsCurrencies) {
    Object.keys(contactData?.accountsCurrencies).forEach(accountNumber => {
      contactData?.accountsCurrencies[accountNumber].forEach(currency => {
        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }
  
        if (!balances[accountNumber].trading.find((item) => item.currency === currency)) {
          balances[accountNumber].trading.push({ currency, amount: 0 });
        }
  
        if (!balances[accountNumber].withdrawal.find((item) => item.currency === currency)) {
          balances[accountNumber].withdrawal.push({ currency, amount: 0 });
        }
      });
    });
  }

  const combinedLoading: boolean = loadingContactInfo || loadingBd1 || loadingBd2 || loading;

  return {
    loading: combinedLoading,
    error,
    data,
    balances,
    refetch,
  };
};


